import { Col, Container, Row } from "react-bootstrap";
import {
	CardComponent,
	CardDashboardComponent,
} from "../components/dashboard/cardComponent";
import { PageTitle } from "../components/pageTitle";
import { Tag } from "react-feather";
import { useEffect, useState } from "react";
import { getTimeSheetsApi, getTurnosApi } from "../api/TurnosApi";
import { GetUserInfoHandler, getTotalTimeWorkedToday } from "../api/UserApiHandler";
import { useNavigate } from "react-router-dom";
import { useFetchItems } from "../hooks/useFetchItems";
import { ApiEndpoints } from "../constants/urls";

export const DashboardPage = () => {
	const [turnos,setTurnos] = useState<any>(null)
	const [totalTime,setTotalTime] = useState("")
	const [lastTasks,setLastTasks] = useState([])
	const navigate = useNavigate()
	const {
		data: TurnosEmployee,
		isLoading: LoadingTurnosEmployee,
		error: ErrorTurnosEmployee,
		fetchItems: FetchTurnosEmployee,
	} = useFetchItems(ApiEndpoints.turnosEmployee);
	useEffect(() => {
	
		/*GetUserInfoHandler().then((userInfo:any) => {
			if (!userInfo.company.data_completed && userInfo.company_role === "owner")
				navigate("/company")
		}) */
		getTotalTimeWorkedToday().then((data: any) => {
			
			setTotalTime(data.total_time)
			setLastTasks(data.last_tasks)
		})
		
	},[])
	return (
		<Container style={{ padding: "0.5em" }}>
			<Row className={"text-center"} style={{marginTop: "1em"}}>
				<PageTitle>Dashboard</PageTitle>
			</Row>
			<Row style={{marginTop: "1em"}}>
				<Col lg={6}>
					<CardDashboardComponent>
						<p style={{ textAlign: "center", fontSize: "16px" }}>
							Horas trabajadas hoy
						</p>
						<h4
							style={{
								textAlign: "center",
								fontSize: "33px",
								marginTop: 0,
								marginBottom: "0.5em",
							}}>
							{" "}
							{totalTime}
						</h4>
					</CardDashboardComponent>
					<CardComponent>
						<div
							style={{
								display: "flex",
								borderBottomStyle: "solid",
								borderBottomWidth: "2px",
								borderBottomColor: "rgba(149, 187, 232, 0.6)",
							}}>
							<p
								style={{
									fontSize: "16px",
									width: "50%",
									fontWeight: 600,
								}}>
								Últimas tareas trabajadas
							</p>
							<p
								style={{
									fontSize: "16px",
									width: "50%",
									fontWeight: 600,
								}}>
								Tiempo dedicado{" "}
							</p>
						</div>
						{lastTasks.map((task:any,index:number) => <div
							key={index}
							style={{
								display: "flex",
								borderBottomStyle: "solid",
								borderBottomWidth: "1px",
								borderBottomColor: "rgba(149, 187, 232, 0.6)",
								alignItems: "center",
								padding: "1em"
							}}>
							<div
								style={{
									width: "50%",
									display: "flex",
									alignItems: "center",
								}}>
								<Tag style={{ marginRight: "1em" }} />
								<div>
									<p style={{ fontSize: "18px",margin:0 }}>{task.project_name}</p>
									<p style={{ fontSize: "16px" }}>
										{" "}
										{task.task_name}
									</p>
								</div>
							</div>
							<div style={{ width: "50%" }}>
								<h3
									style={{
										fontSize: "16px",
										textAlign: "start",
										fontWeight: "600",
										color: "rgba(48,100,277, 1)",
									}}>
									{task.time_worked}
								</h3>
							</div>
						</div>)}
						
					</CardComponent>
				</Col>
				<Col lg={6}>
					<CardDashboardComponent>
						<p
							style={{
								borderBottomStyle: "solid",
								borderBottomWidth: "2px",
								borderBottomColor: "rgba(149, 187, 232, 0.6)",
								padding: "0.1em",
								fontWeight: 600,
								paddingBottom: "0.5em",
							}}>
							Tus turnos de Hoy
						</p>
						
						{turnos != null && turnos.map(
							(element:any,index:number) => {
								return <Container style={{borderBottomStyle: "solid", borderBottomWidth: ".1em",borderColor: "rgba(149, 187, 232, 0.3)"}}>
									<p style={{color: element.color,marginBottom: 0}}>{element.name}</p>
									{element.times.map((time:any,index:number) => <p style={{marginTop: 0}}>
										{time.start_time} - {time.end_time}
									</p>)}
								</Container>
							}
						)}
						
					</CardDashboardComponent>
				</Col>
			</Row>
			
		</Container>
	);
};
