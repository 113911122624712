import { Col, Form, Row } from "react-bootstrap";
import { CardComponent } from "../../components/dashboard/cardComponent";
import { TitleText } from "../login/LoginStyles";
import Container from "react-bootstrap/Container";
import { AddButtonStyled } from "../../components/buttonComponent/buttonStyled";
import { useNavigate } from "react-router-dom";
import { LabelStyled } from "../../components/formComponents/labelStyled";
import { InputStyled } from "../../components/formComponents/inputStyled";
import { SelectInputStyled } from "../../components/formComponents/selectStyled";
import { ClientFormSelect } from "../../components/formComponents/clientFormSelect";
import { toast } from "react-toastify";
import { EmployeeFormSelect } from "../../components/formComponents/employeeFormSelect";
import { useCreateItem } from "../../hooks/useCreateItems";
import { ApiEndpoints } from "../../constants/urls";
import { useState } from "react";

export const ProjectCreatePage = () => {
	const navigate = useNavigate();
	const { data, isLoading, error, createItem } = useCreateItem(
		`${ApiEndpoints.project}/`
	);

	const createProjectHandler = async (event: any) => {
		event.preventDefault();
		const formData = event.target;
		let hasErrors = false;

		const fields = [
			{ name: "name", message: "Es necesario establecer el nombre del proyecto" },
			{ name: "client", message: "Es necesario especificar el cliente en el proyecto" },
			{ name: "divisa", message: "Es necesario especificar la divisa del proyecto" },
			{ name: "color", message: "Es necesario especificar el color del proyecto" },
			{ name: "projectBoss", message: "Es necesario seleccionar un jefe de proyecto" },
			{ name: "due_date", message: "Es necesario establecer la fecha de entrega" },
		];

		for (const field of fields) {
			if (formData[field.name].value === "") {
				toast.error(field.message);
				hasErrors = true;
			}
		}

		if (hasErrors) {
			return;
		}

		await createItem({
			name: formData.name.value,
			client: formData.client.value,
			divisa: formData.divisa.value,
			color: formData.color.value,
			project_ow: formData.projectBoss.value,
			due_date: formData.due_date.value
		});

		if (error) {
			toast.error(`Error creando el proyecto \n ${error}`);
		} else {
			navigate("/project");
		}
	};

	return (
		<Container style={{ padding: "1em" }}>
			<TitleText>Crear nuevo proyecto</TitleText>
			<CardComponent>
				<form onSubmit={createProjectHandler}>
					<Row>
						<Col lg={10} md={10} xs={12}>
							<Form.Group>
								<LabelStyled className="project_input_label">
									Nombre del proyecto
								</LabelStyled>
								<InputStyled
									name="name"
									className="project_input_field"
									type="text"
									placeholder="Nombre"
									data-testid="project-name-input"
								/>
							</Form.Group>
						</Col>
						<Col lg={2} md={2} xs={12}>
							<Form.Group>
								<LabelStyled>Divisa</LabelStyled>
								<SelectInputStyled name="divisa" data-testid="project-divisa-select">
									<option value="">Seleccione</option>
									<option value="eur">EUR</option>
									<option value="usd">USD</option>
									<option value="ngn">NGN</option>
									<option value="cop">COP</option>
								</SelectInputStyled>
							</Form.Group>
						</Col>
					</Row>
					<Row style={{ marginTop: "2em" }}>
						<Col lg={10} md={6} xs={12}>
							<Form.Group>
								<LabelStyled className="project_input_label">
									Nombre del cliente
								</LabelStyled>
								<ClientFormSelect
									name="client"
									onChange={() => {}}
									data-testid="project-client-select"
								/>
							</Form.Group>
						</Col>
						<Col lg={2} md={6} xs={12}>
							<Form.Group>
								<LabelStyled>Color</LabelStyled>
								<SelectInputStyled name="color" data-testid="project-color-select">
									<option value="">Seleccione</option>
									<option value="red">Rojo</option>
									<option value="blue">Azul</option>
									<option value="yellow">Amarillo</option>
								</SelectInputStyled>
							</Form.Group>
						</Col>
					</Row>
					<Row style={{ marginTop: "0.5em" }}>
						<Col lg={12}>
							<LabelStyled>
								* Configura la asignacion de empleados dentro del proyecto
							</LabelStyled>
						</Col>
					</Row>
					<Row style={{ marginTop: "1em" }}>
						<Col>
							<LabelStyled>Jefe de proyecto</LabelStyled>
							<EmployeeFormSelect
								name="projectBoss"
								onChange={(event: any) => {}}
								data-testid="project-boss-select"
							/>
						</Col>
					</Row>
					<Row>
						<Col>
							<LabelStyled className="project_input_label">
								Fecha de entrega
							</LabelStyled>
							<Form.Control name="due_date" type="date" data-testid="project-due-date-input" />
						</Col>
					</Row>
					<Row style={{ marginTop: "2em" }} className="flex-row-reverse">
						<Col lg={2} md={2} xs={6}>
							<AddButtonStyled
								style={{
									borderRadius: "0.3em",
									padding: ".2em",
								}}
								data-testid="project-submit-button"
								disabled={isLoading}
							>
								Guardar
							</AddButtonStyled>
						</Col>
					</Row>
				</form>
			</CardComponent>
		</Container>
	);
};
