import { FC } from "react";
import xIcon from "../../assets/images/x.svg";
import {
  Container,
  NavLinkContainer,
  ImgIcon,
  CustomLink,
} from "./LeftBarStyles";
import { useNavigate } from "react-router-dom";
import { X } from "react-feather";

interface LeftBarComponentProps {
  setLeftBar: any;
}

export const LeftBarComponent: FC<LeftBarComponentProps> = ({ setLeftBar }) => {
  const dashboardRoute = "/";
  const employeesRoute = "/employee/";
  const navigate = useNavigate();
  return (
    <Container style={{zIndex: 99}}>
      <NavLinkContainer>
        <X
          color="white"
          onClick={(prevState) => setLeftBar(!prevState)}
        />
        <CustomLink onClick={() => {navigate("/"); setLeftBar(false)}} to={dashboardRoute}>Dashboard</CustomLink>
        <CustomLink onClick={() => {navigate("/client"); setLeftBar(false)}} to={"/client"}>Clientes</CustomLink>
        <CustomLink onClick={() => {navigate("/employee"); setLeftBar(false)}} to={employeesRoute}>Empleados</CustomLink>
        <CustomLink onClick={() => {navigate("/calendar"); setLeftBar(false)}} to={"/calendar"}>Horarios</CustomLink>
        <CustomLink onClick={() => {navigate("/holiday"); setLeftBar(false)}} to={"/holiday"}>Vacaciones</CustomLink>
        <CustomLink onClick={() => {navigate("/project"); setLeftBar(false)}} to={"/project"}>Proyectos</CustomLink>
        <CustomLink onClick={() => {navigate("/task"); setLeftBar(false)}} to={"/task"}>Tareas</CustomLink>
        <CustomLink onClick={() => {navigate("/turnos"); setLeftBar(false)}} to={"/turnos"}>Turnos</CustomLink>
        <CustomLink onClick={() => {navigate("/fichajes"); setLeftBar(false)}} to={"/fichajes"}>Fichajes</CustomLink>
        <CustomLink onClick={() => {navigate("/attachment"); setLeftBar(false)}} to={"/attachment"}>Adjuntos</CustomLink>

      </NavLinkContainer>
    </Container>
  );
};
