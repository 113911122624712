

export const ApiEndpoints= {
    attachment: `${process.env.REACT_APP_BASE_URL}api/v1/attachment`,
    folder: `${process.env.REACT_APP_BASE_URL}api/v1/folder`,
    client: `${process.env.REACT_APP_BASE_URL}api/v1/client`,
    task: `${process.env.REACT_APP_BASE_URL}api/v1/task`,
    project: `${process.env.REACT_APP_BASE_URL}api/v1/project`,
    employee: `${process.env.REACT_APP_BASE_URL}api/v1/employee`,
    turnosEmployee: `${process.env.REACT_APP_BASE_URL}api/v1/turnos/employee`,
    notification: `${process.env.REACT_APP_BASE_URL}api/v1/notifications`,
    holiday: `${process.env.REACT_APP_BASE_URL}api/v1/holiday`
}