import { useCallback, useState } from "react"
import { apiClient } from "../api/axiosClient"
import { AxiosError } from "axios"



export const useCreateItem = (url: string ) => {
    const [isLoading,setIsLoading] = useState(false)
    const [error,setError] = useState<any>(null)
    const [data,setData] = useState<any>(null)
    const createItem = useCallback(async (newObjectdata: any) => {
        const token =localStorage.getItem("token")
        setIsLoading(true)
        const configuration: any = {
          headers:{
              "Authorization": `Bearer ${token}`
          },
        }
        if (newObjectdata instanceof FormData){
          configuration["headers"]["Content-Type"] = "multipart/form-data"
        }
        try{
            const request = await apiClient.post(url,
              newObjectdata,
              configuration
              )
            console.log([200,201].includes(request.status))
            if ([200,201].includes(request.status)){
              setData(request.data)
            }
            else{
              setError(true)
            }
        }
        catch (error:any) {
          setError('Error creando el elemento');
          if (error instanceof AxiosError) {
            // Los errores que da Django los hace por campo por lo tanto tenemos que agrupar.
            let errorMsg: string[] = []
            Object.keys(error.response!.data).forEach((key) =>{
              errorMsg.push(`${key} : ${error.response!.data[key]}`)
            })
            setError(errorMsg.join("\n"));
          }
        } finally {
          setIsLoading(false);
        }
    },[url])
    return {data,isLoading,error,createItem}
}