import styled from "styled-components";
import { COLORS } from "../../constants/colors";
import { Link } from "react-router-dom";

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: ${COLORS.primaryBlue};
  padding: 20px 20px 20px 20px;
  height: 25px;
  width: 100%;
  padding: 2em;
  img {
    cursor: pointer;
  }
`;

export const ImgCointainer = styled.div`
  width: 100%;
  height: 150%;
`;

export const ImgIcon = styled.img`
  width: 24px;
  height: 24px;
`;

export const UserImg = styled.img`
  width: 50px;
  height: 50px;
  border-radius: 50%;
`;

export const SideContainer = styled.div`
  width: 150px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  @media (min-width: 900px) {
    width: 300px;
  }
`;

export const SideLeftContainer = styled.div`
  width: 100px;
  display: flex;
  align-items: center;
  @media (min-width: 900px) {
    width: 80%;
  }
`;

export const LogoImgContainer = styled.div`
  height: 100%;
`;

export const BurgerContainer = styled.div`
  width: 100%;
  height: 100%;
  visibility: visible;
  position: relative;
  left: 0;
  @media (min-width: 900px) {
    visibility: hidden;
    position: absolute;
    left: -150px;
  }
`;

export const LogoImg = styled.img`
  width: 48px;
  height: 48px;
`;

export const UserInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  visibility: hidden;
  position: absolute;
  top: -200px;
  @media (min-width: 900px) {
    visibility: visible;
    position: relative;
    top: 0;
  }
`;
export const UserContainer = styled.div`
  margin-right: 1em;
  height: 100%;
`;

export const NavLinkContainer = styled.div`
  position: absolute;
  top: -500px;
  visibility: hidden;

  @media (min-width: 900px) {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 700px;
    height: 100%;
    justify-content: space-around;
    position: relative;
    top: -0;
    visibility: visible;
  }
`;

export const CustomLink = styled(Link)<{selected?: boolean}>`
  text-decoration: none;
  color: ${props => props.selected ? "black" : COLORS.primaryWhite};
  background-color: ${props => props.selected ? "white" : "transparent"};
  font-size: 14px;
  line-height: 14px;
  font-weight: 600;
  padding: .5em;
  border-radius: 0.3em;
`;

export const Name = styled.span`
  font-size: 16px;
  line-height: 21px;
  font-weight: 600;
  color: ${COLORS.primaryWhite};
`;

export const Email = styled.span`
  font-size: 12px;
  line-height: 16px;
  font-weight: 600;
  color: ${COLORS.primaryWhite};
`;
