import { useNavigate, useParams, useResolvedPath } from "react-router-dom";
import { Col, Container, FormCheck, Row } from "react-bootstrap";
import { ArrowLeft, ArrowRight, Calendar, Circle } from "react-feather";
import { SelectButtonStyled } from "../../components/EmployeeComponents/buttonTypes";
import { useEffect, useState } from "react";
import { deleteEmployeeApi } from "../../api/EmployeeApiHandler";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { TaskTableComponent } from "./TableComponents/TaskComponent";
import {
	getEmployeeCalendarApi,
	getEmployeeProjectApi,
	getEmployeeTasksApi,
} from "../../api/TaskApiHandler";
import { ProjectTableComponent } from "../../components/ProjectTableComponent/ProjectTableComponent";
import { CalendarWeekComponent } from "../../components/calendarWeekComponent/CalendarWeekComponent";
import { useFetchItems } from "../../hooks/useFetchItems";
import { ApiEndpoints } from "../../constants/urls";
import { TurnosButtonSelectStyled } from "../../components/buttonComponent/buttonStyled";
import { getTurnosApi } from "../../api/TurnosApi";

export const EmployeeDetailPage = () => {
	const { id } = useParams();
	const navigate = useNavigate();

	const { data, isLoading, error, fetchItems } = useFetchItems(
		ApiEndpoints.employee,
		parseInt(id)
	);
	const [tableComponent, setTableComponent] = useState();
	const [detailSection, setDetailSection] = useState("project");
	const MySwal = withReactContent(Swal);
	const [turnosTime, setTurnosTime] = useState("week");
	const [turnos, setTurnos] = useState(null);
	const NextTimeBlock = (event) => {
		/* Consultamos el siguiente bloque de tiempo, siguiente dia, semana o mes */
		getTurnosApi(turnosTime, "next", turnos.data[turnos.days[0]].date).then(
			(data) => {
				setTurnos(data);
				generateTurnosComponent();
			}
		);
	};
	const PrevTimeBlock = (event) => {
		/* Consultamos el siguiente bloque de tiempo, siguiente dia, semana o mes */
		getTurnosApi(turnosTime, "prev", turnos.data[turnos.days[0]].date).then(
			(data) => {
				setTurnos(data);
				generateTurnosComponent();
			}
		);
	};
	const generateTurnosComponent = () => {
		setTableComponent();
	};
	useEffect(() => {
		getTurnosApi(turnosTime).then((data) => {
			setTurnos(data);
		});
	}, [turnosTime]);

	useEffect(() => {
		fetchItems();
	}, []);

	
	useEffect(() => {
		switch (detailSection) {
			case "project":
				getEmployeeProjectApi(parseInt(id)).then(
					(getEmployeeProjectApi) =>
						setTableComponent(
							<ProjectTableComponent
								projectList={getEmployeeProjectApi}
							/>
						)
				);
				break;
			case "task":
				getEmployeeTasksApi(parseInt(id)).then((employeeTasks) =>
					setTableComponent(
						<TaskTableComponent isEmployeePage={true} taskList={employeeTasks} />
					)
				);
				break;
			case "calendar":
				getEmployeeCalendarApi(parseInt(id)).then((calendarEvents) =>
					setTableComponent(
						<CalendarWeekComponent onlyDay={true} events={calendarEvents} />
					)
				);
				break;

			case "turnos":
				generateTurnosComponent();
				break;
		}
	}, [detailSection]);

	return (
		<>
			<Container style={{ justifyContent: "start" }}>
				{data && (
					<Col>
						<Row style={{ marginTop: "1em" }}>
							<Col style={{ textAlign: "center" }}>
								<div
									style={{
										display: "flex",
										justifyContent: "space-between",
									}}>
									<div style={{ display: "flex" }}>
										<button
											onClick={() => {
												navigate("/employee");
											}}
											style={{
												backgroundColor:
													"rgba(48, 100, 217, 0.2)",
												borderRadius: ".5em",
												borderColor: "transparent",
												paddingLeft: ".6em",
												paddingRight: ".6em",
											}}>
											<ArrowLeft color="rgba(48, 100, 217, 1)" />
										</button>
										<p
											style={{
												marginBottom: 0,
												fontSize: "1.4rem",
												fontWeight: 800,
												fontFamily: "Roboto",
												lineHeight: "1.6 rem",
												color: "rgba(48, 100, 217, 1)",
												marginLeft: ".5em",
											}}>
											Empleados
										</p>
									</div>
									<div>
										<SelectButtonStyled
											active={true}
											onClick={() =>
												navigate(`/employee/edit/${id}`)
											}>
											Editar
										</SelectButtonStyled>
										<SelectButtonStyled
											active={false}
											onClick={() => {
												MySwal.fire({
													title: "¿Estas seguro de eliminar el cliente? ",
													showCancelButton: true,
													confirmButtonText:
														"Eliminar",
													cancelButtonText: `No eliminar`,
													confirmButtonColor: "red",
												}).then((result) => {
													if (result.isConfirmed) {
														deleteEmployeeApi(id);
														navigate("/employee");
													}
												});
											}}>
											Eliminar
										</SelectButtonStyled>
									</div>{" "}
								</div>
							</Col>
						</Row>
						<Row style={{ marginTop: "2em" }}>
							<Col className="text-center">
								<div style={{ display: "flex" }}>
									<img
										style={{
											width: "100px",
											height: "auto",
										}}
										className="rounded-circle"
										alt="avatar1"
										src={`${process.env.REACT_APP_BASE_URL}${data.photo}`}
									/>
									<h1
										style={{
											fontSize: "36px",
											fontWeight: 500,
											fontFamily: "Roboto",
											marginTop: "1em",
											marginLeft: "1em",
										}}>
										{data.name} {data.last_name}
									</h1>
								</div>
							</Col>
						</Row>
						<Row>
							<Col lg={8}>
								<Row
									style={{
										width: "40%",
										marginTop: "1em",
									}}>
									<Col lg={6}>
										<p
											style={{
												margin: 0,
												fontSize: "0.8rem",
												fontFamily: "Roboto",
												fontWeight: 400,
											}}>
											Hoy
										</p>
										<p
											style={{
												fontWeight: 700,
												fontSize: "1.4rem",
												fontFamily: "Roboto",
												color: "rgba(0, 0, 0, 0.5)",
											}}>
											{data.today_time}
										</p>
									</Col>
									<Col lg={6}>
										<p
											style={{
												margin: 0,
												fontSize: "0.8rem",
												fontFamily: "Roboto",
												fontWeight: 400,
											}}>
											30 días
										</p>
										<p
											style={{
												fontWeight: 700,
												fontSize: "1.4rem",
												fontFamily: "Roboto",
											}}>
											{data.today_time}
										</p>
									</Col>
								</Row>
							</Col>
						</Row>
						<Row
							style={{
								backgroundColor: "rgba(48, 100, 217, 0.1)",
							}}>
							<Col className="text-center">
								<SelectButtonStyled
									active={detailSection === "project"}
									onClick={() => setDetailSection("project")}>
									<p style={{ margin: 0 }}>Proyectos</p>
								</SelectButtonStyled>
								{/*TODO: Lo dejamos como comentado para posteriormente si es necesario volver a usarlo<SelectButtonStyled
									active={detailSection === "task"}
									onClick={() => setDetailSection("task")}>
									{" "}
									<p style={{ margin: 0 }}>Tareas</p>
								</SelectButtonStyled>*/}
								<SelectButtonStyled
									active={detailSection === "calendar"}
									onClick={() =>
										setDetailSection("calendar")
									}>
									{" "}
									<p style={{ margin: 0 }}>Tiempo</p>
								</SelectButtonStyled>
								<SelectButtonStyled
									active={detailSection === "turnos"}
									onClick={() => setDetailSection("turnos")}>
									<p style={{ margin: 0 }}>Turnos</p>
								</SelectButtonStyled>
							</Col>
						</Row>
						<Row style={{ marginTop: "1em" }}>{tableComponent}</Row>
						{detailSection === "turnos" ? (
							<div style={{ backgroundColor: "white" }}>
							<Container
								style={{
									display: "flex",
									justifyContent: "space-around",
									marginTop: "1em",
									backgroundColor: "white",
									padding: "1em",
								}}>
								<Col lg={2}>
									<TurnosButtonSelectStyled
										onClick={PrevTimeBlock}>
										<ArrowLeft />
									</TurnosButtonSelectStyled>
								</Col>
								<Col lg={4}>
									<TurnosButtonSelectStyled>
										{turnos && turnos.days[0]} /{" "}
										{turnos &&
											turnos.days[
												turnos.days.length - 1
											]}
									</TurnosButtonSelectStyled>
								</Col>
								<Col lg={2}>
									<TurnosButtonSelectStyled
										onClick={NextTimeBlock}>
										<ArrowRight />
									</TurnosButtonSelectStyled>
								</Col>
							</Container>
							<Container style={{ marginTop: "2em" }}>
								<Row>
									<Col lg={3} />
									<Col lg={9}>
										<Row>
											{turnos &&
												turnos.days.map(
													(el, index) => (
														<Col>
															<p key={el}>
																{el.toUpperCase()}
															</p>
														</Col>
													)
												)}
										</Row>
									</Col>
								</Row>
								<Row>
									<Col lg={3}>
										<div>
											{turnos &&
												Object.keys(
													turnos.turnos
												).map((el, index) => (
													<Col>
														<p key={el}>
															{el.toUpperCase()}
														</p>
													</Col>
												))}
										</div>
									</Col>
									<Col lg={9}>
										{Object.keys(turnos.turnos).map(
											(turno, index) => (
												<Row>
													{turnos.days.map(
														(
															turnoDay,
															index
														) => (
															<Col>
																<p>
																	{
																		turnos
																			.data[
																			turnoDay
																		]
																			.tasks[
																			turno
																		]
																	}
																</p>
															</Col>
														)
													)}
												</Row>
											)
										)}
									</Col>
								</Row>
							</Container>
						</div>
						) : (
							<></>
						)}
					</Col>
				)}
			</Container>
		</>
	);
};
