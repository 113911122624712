import { Plus } from "react-feather";
import { AddButtonStyled } from "../../components/buttonComponent/buttonStyled";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { InputComponentStyled } from "../../components/inputComponent/inputComponent";
import { TitleText } from "../login/LoginStyles";
import {
	EmployeeTableName,
	EmployeeTableText,
} from "../../components/EmployeeList/employeeTableStyled";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { TaskInterface } from "../../interfaces/task";
import { useFetchItems } from "../../hooks/useFetchItems";
import { ApiEndpoints } from "../../constants/urls";
import { SwitcherStyled } from "../../components/switchComponent/switchComponentStyled";

export const TaskListPage = () => {
	const navigate = useNavigate();
	const { data, isLoading, error, fetchItems } = useFetchItems(
		ApiEndpoints.task
	);
	const [taskList, setTaskList] = useState<TaskInterface[]>([]);
	const [showTasks,setShowTasks] = useState([])
	const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 991);
	const [showAllTasks,setShowAllTask] = useState(true)
	const [showFinishedTask,setShowFinishedTasks] = useState(false)

	useEffect(() => {
		if (data !== null && taskList.length === 0) {
			setTaskList(data.filter((task: TaskInterface) => task.status !== "end"))
			setShowTasks(data.filter((task: TaskInterface) => task.status !== "end"))
		};
	}, [data]);
	useEffect(() => {
		fetchItems();
	}, []);

	const filterTableHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
		const filter = event.target.value;
		if (filter === "") setTaskList(data);
		const clients = data.filter((Task: any) => {
			let fullName = Task.name;
			if (fullName.includes(filter)) return Task;
		});
		setTaskList(clients);
	};

	useEffect(() => {
		const handleResize = () => {
			setIsMobileView(window.innerWidth <= 991);
		};
		window.addEventListener("resize", handleResize);
		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, []);

	return (
		<Container
			fluid
			style={{ padding: "1em" }}>
			{isMobileView && <TitleText> Tareas</TitleText>}
			<Row>
				<Col
					lg={2}
					md={12}
					sm={12}
					style={{ marginRight: "1em" }}>
					<AddButtonStyled
						onClick={() => {
							navigate("/task/create");
						}}>
						<Plus /> Añadir tarea
					</AddButtonStyled>
					<InputComponentStyled
						placeholder="Buscar"
						onChange={filterTableHandler}
					/>
					<SwitcherStyled
						type="switch"
						label="Mostrar todas los tareas"
						id="all"
						onChange={() => {
							setShowAllTask(true)
							setShowFinishedTasks(false)
							setShowTasks(data.filter((task: TaskInterface) => task.status !== "end"))
						}}
						checked={showAllTasks}
					/>
					<SwitcherStyled
						type="switch"
						label="Tareas Finalizadas"
						id="ended"
						onChange={()=>{
							setShowAllTask(false)
							setShowFinishedTasks(true)
							setShowTasks(data.filter((task: any) => task.status === "end"))
						}}
						checked={showFinishedTask}
					/>
				</Col>
				<Col
					lg={9}
					md={12}
					sm={12}>
					{!isMobileView && <TitleText> Tareas</TitleText>}
					<Container
						fluid
						style={{
							backgroundColor: "white",
							paddingTop: "1em",
							borderRadius: ".3em",
							height: "100%",
							marginTop: isMobileView ? "1em" : "0",
						}}>
						<Row
							style={{
								height: "2em",
								textAlign: "start",
								padding: " .5em 1em 1em 1em",
							}}>
							<Col lg={4}>
								<EmployeeTableName>Tarea</EmployeeTableName>
							</Col>
							<Col lg={4}>
								<EmployeeTableText>Proyecto</EmployeeTableText>
							</Col>
							<Col lg={4}>
								<EmployeeTableText>Empleado</EmployeeTableText>
							</Col>
						</Row>
						<hr
							style={{
								color: "rgba(149, 187, 232, 0.6)",
								borderWidth: "2px",
								marginTop: 0,
								marginBottom: 0,
							}}
						/>
						{showTasks &&
							showTasks.map((Task: TaskInterface) => (
								<div
									onClick={() => {
										navigate(`/task/${Task.id!}`);
									}}>
									<Row
										style={{
											height: "3em",
											textAlign: "start",
											padding: "2em",
										}}>
										<Col lg={4}>
											<EmployeeTableName
												style={{
													wordBreak: "break-word",
												}}>
												{Task.name}
											</EmployeeTableName>
										</Col>
										<Col lg={4}>
											<EmployeeTableText>
												{Task.project_name}
											</EmployeeTableText>
										</Col>
										<Col lg={4}>
											<EmployeeTableText>
												{Task.employee_name}
											</EmployeeTableText>
										</Col>
									</Row>
									<hr
										style={{
											color: "rgba(149, 187, 232, 0.6)",
											borderWidth: "2px",
											marginTop: "1em",
											marginBottom: 0,
										}}
									/>
								</div>
							))}
					</Container>
				</Col>
			</Row>
		</Container>
	);
};
