import { FC } from "react";
import { Outlet } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { TopBarComponent } from "../components/topBar/TopBarComponent";
import { useState } from "react";
import { LeftBarComponent } from "../components/leftBar/LeftBarComponent";
import { ToastContainer } from "react-toastify";
import { InputadorComponent } from "../components/Inputador";
import { Col, Row } from "react-bootstrap";

export const Layout: FC = () => {
	const location = useLocation();
	const [leftBar, setLeftBar] = useState(false);

	return (
		<>
			<div
				style={{
					backgroundColor: "rgba(128, 128, 128, 0.1)",
					minHeight: "90vh",
					width: "100wh",
				}}>
				<ToastContainer />
				<TopBarComponent
					setLeftBar={setLeftBar}
					location={location.pathname.split("/")[1]}
				/>
				{leftBar && <LeftBarComponent setLeftBar={setLeftBar} />}
				<main>
					<Outlet key={location.pathname} />
					<Row
						className="flex-row-reverse "
            style={{marginTop: "5em"}}
            >
						<Col
							lg={3}
							md={6}
							xs={12}>
							<InputadorComponent />
						</Col>
					</Row>
				</main>
			</div>
		</>
	);
};
