import { Container } from "react-bootstrap";
import { CalendarWeekComponent } from "../../components/calendarWeekComponent/CalendarWeekComponent";
import { getEmployeeCalendarApi } from "../../api/TaskApiHandler";
import { useEffect, useState } from "react";
import { useMe } from "../../hooks/useMe";
import { DownloadButtonStyled } from "../../components/EmployeeComponents/buttonTypes";
import { AddButtonStyled } from "../../components/buttonComponent/buttonStyled";
import { useNavigate } from "react-router-dom";

export const CalendarPage = () => {
  const [events, setEvents] = useState([]);
  const user = useMe();
  const navigate = useNavigate()
  useEffect(() => {
    if (user.id)
      getEmployeeCalendarApi(parseInt(user.id!.toString())).then(
        (calendarEvents: any) => {
          console.log(calendarEvents);
          setEvents(calendarEvents);
        }
      );
  }, [user]);
  return (
    <Container
      fluid
      style={{
        padding: "1.5em",
        fontFamily: "Roboto",
        maxWidth: "100%",
        minHeight: "100%",
      }}
    >
      <AddButtonStyled
        onClick={() => {
          console.log();
          navigate("/inputacion/create");
        }}
      >
        Crear Imputación
      </AddButtonStyled>
      <CalendarWeekComponent onlyDay={false} events={events} />
    </Container>
  );
};
