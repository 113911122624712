import { useEffect, useState } from "react";
import { deleteHolidayApi, getHolidayApi, updateHolidayApi } from "../../api/HolidayApiHandler";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { ArrowLeft, Plus } from "react-feather";
import { SelectButtonStyled } from "../../components/EmployeeComponents/buttonTypes";
import Modal from "react-bootstrap/Modal";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import axios from "axios";
import {
  EmployeeTableName,
  EmployeeTableText,
} from "../../components/EmployeeList/employeeTableStyled";
import { ApiEndpoints } from "../../constants/urls";
import { useFetchItems } from "../../hooks/useFetchItems";
import { isTemplateLiteral } from "typescript";
import { getEmployeeListApi } from "../../api/EmployeeApiHandler";
export const HolidayDetailPage = () => {
  const [holiday, setHoliday] = useState<any>(null);
  const [employeeList, setEmployeeList] = useState<any>([]);
  const [openAddEmployeeModal, setOpenAddEmployeeModal] = useState(false);
  const [newEmployeeList, setNewEmployeeList] = useState<any>([]);
  const { id } = useParams();
  const MySwal = withReactContent(Swal);
  const navigate = useNavigate();
  useEffect(() => {
    const getHoliday = async () => {
      const data = await getHolidayApi(id);
      setNewEmployeeList(data.users)
      setHoliday(data);
    };
    const getEmployee = async () => {
      const data = await getEmployeeListApi();
      setEmployeeList(data);
    };
    getHoliday();
    getEmployee();
  }, []);

  console.log(holiday);
  console.log(newEmployeeList);
  return (
    <Container style={{ justifyContent: "start" }}>
      {holiday && (
        <Col>
          <Row style={{ marginTop: "1em" }}>
            <Col style={{ textAlign: "center" }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <div style={{ display: "flex" }}>
                  <button
                    onClick={() => {
                      navigate("/holiday");
                    }}
                    style={{
                      backgroundColor: "rgba(48, 100, 217, 0.2)",
                      borderRadius: ".5em",
                      borderColor: "transparent",
                      paddingLeft: ".6em",
                      paddingRight: ".6em",
                    }}
                  >
                    <ArrowLeft color="rgba(48, 100, 217, 1)" />
                  </button>
                  <p
                    style={{
                      marginBottom: 0,
                      fontSize: "1.4rem",
                      fontWeight: 800,
                      fontFamily: "Roboto",
                      lineHeight: "1.6 rem",
                      color: "rgba(48, 100, 217, 1)",
                      marginLeft: ".5em",
                    }}
                  >
                    Vacaciones
                  </p>
                </div>
                <div>
                  <SelectButtonStyled
                    active={false}
                    onClick={() => {
                      MySwal.fire({
                        title: "¿Estas seguro de eliminar el turno de vacaciones? ",
                        showCancelButton: true,
                        confirmButtonText: "Eliminar",
                        cancelButtonText: `No eliminar`,
                        confirmButtonColor: "red",
                      }).then((result) => {
                        if (result.isConfirmed) {
                          deleteHolidayApi(id)
                          window.location.href ="/holiday"
                        }
                      });
                    }}
                  >
                    Eliminar
                  </SelectButtonStyled>
                </div>{" "}
              </div>
            </Col>
          </Row>
          <Row style={{ marginTop: "2em" }}>
            {holiday && <Col className="text-center">
              <div
                style={{
                  textAlign: "start",
                  marginLeft: "1em",
                  alignContent: "start",
                }}
              >
                <h1
                  style={{
                    fontSize: "36px",
                    fontWeight: 500,
                    fontFamily: "Roboto",
                    margin: 0,
                  }}
                >
                  {holiday.name}
                </h1>
              </div>
              <div
                style={{
                  textAlign: "start",
                  marginLeft: "1em",
                  alignContent: "start",
                }}
              >
                <h4
                  style={{
                    fontSize: "14px",
                    fontWeight: 500,
                    fontFamily: "Roboto",
                    margin: 0,
                  }}
                >
                  Comienzo de vacaciones: {holiday.times[0].start_date}
                </h4>
                <h4
                  style={{
                    fontSize: "14px",
                    fontWeight: 500,
                    fontFamily: "Roboto",
                    margin: 0,
                  }}
                >
                  Final de vacaciones: {holiday.times[0].end_date}
                </h4>
              </div>
            </Col>}
          </Row>
          <Row style={{ marginTop: "1em" }}>
            <Container
              style={{
                backgroundColor: "white",
                paddingTop: "1em",
                borderRadius: ".3em",
                height: "100%",
              }}
            >
              <Row
                style={{
                  height: "2em",
                  textAlign: "start",
                  padding: " .5em 1em 1em 1em",
                }}
              >
                <Col lg={3}>
                  <EmployeeTableName>Empleado</EmployeeTableName>
                </Col>
              </Row>
              <hr
                style={{
                  color: "rgba(149, 187, 232, 0.6)",
                  borderWidth: "2px",
                  marginTop: 0,
                  marginBottom: 0,
                }}
              />
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  padding: "1em",
                  fontWeight: 700,
                  fontSize: "1rem",
                  fontFamily: "Roboto",
                  color: "rgba(48, 100, 217, 1)",
                }}
                onClick={(event) => setOpenAddEmployeeModal(true)}
              >
                <p style={{ marginBottom: 0 }}>
                  {" "}
                  <Plus /> Editar Empleados{" "}
                </p>
                <hr
                  style={{
                    color: "rgba(149, 187, 232, 0.6)",
                    borderWidth: "2px",
                    marginTop: "1em",
                    marginBottom: 0,
                  }}
                />
              </div>
              {employeeList &&
                employeeList.map((employee: any) => {
                  if (holiday.users.includes(employee.id)) {
                    return (
                      <div
                        key={employee.id}
                        onClick={() => {
                          navigate(`/employee/${employee.id}`);
                        }}
                      >
                        <Row
                          style={{
                            height: "3em",
                            textAlign: "start",
                            padding: "2em",
                          }}
                        >
                          <Col lg={3}>
                            <EmployeeTableName>
                              {employee.name} {employee.last_name}
                            </EmployeeTableName>
                          </Col>
                        </Row>
                        <hr
                          style={{
                            color: "rgba(149, 187, 232, 0.6)",
                            borderWidth: "2px",
                            marginTop: "1em",
                            marginBottom: 0,
                          }}
                        />
                      </div>
                    );
                  }
                })}
            </Container>
          </Row>
        </Col>
      )}
      <Modal
        centered
        show={openAddEmployeeModal}
        onHide={() => {
          setOpenAddEmployeeModal(false);
        }}
        contentLabel="Selecciona el listado de empleados de las vacaciones"
      >
        <Modal.Header>
          <Modal.Title>Editar empleados asignados</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {employeeList &&
            employeeList!.map((employee: any, index: number) => {
              let employeeInsideList = newEmployeeList.filter(
                (el: any) => el === employee.id
              );
              return (
                <div key={index}>
                  <Row
                    key={index}
                    style={{
                      height: "3em",
                      textAlign: "start",
                      padding: "2em",
                    }}
                  >
                    <Col lg={2} md={2} xs={2}>
                      {
                        <Form.Check
                          onClick={(event: any) => {
                            let data: any = [];
                            if (newEmployeeList.includes(employee.id!)) {
                              let temporal = newEmployeeList;
                              data = temporal.filter(
                                (e: any) => e != parseInt(event.target.id)
                              );
                            } else {
                              data = [
                                ...newEmployeeList,
                                parseInt(event.target.id),
                              ];
                            }
                            setNewEmployeeList(data);
                          }}
                          id={employee.id!.toString()}
                          checked={newEmployeeList.includes(employee.id!)}
                        />
                      }
                    </Col>

                    <Col lg={10} md={10} xs={10}>
                      <EmployeeTableName>
                        {employee.name} {employee.last_name}
                      </EmployeeTableName>
                    </Col>
                  </Row>
                  <hr
                    style={{
                      color: "rgba(149, 187, 232, 0.6)",
                      borderWidth: "2px",
                      marginTop: "1em",
                      marginBottom: 0,
                    }}
                  />
                </div>
              );
            })}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => {window.location.reload()}}>
            Cancelar
          </Button>
          <Button
            variant="primary"
            onClick={async () => {
              updateHolidayApi(id!, newEmployeeList);
              window.location.reload()
            }}
          >
            Agregar Empleados
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};
