import { Navigate, Outlet } from "react-router-dom";
import { isLoggedIn } from "../..";
import { FC } from "react";


export const PrivateRoute: FC = () => {
  if (isLoggedIn() === true) {
    return <Outlet />;
  }

  return <Navigate to="/login" />;
};
