import logo from "../../assets/images/logo-little.svg";
import burger from "../../assets/images/burger.svg";
import bell from "../../assets/images/bell.svg";
import messages from "../../assets/images/messages.svg";
import userIcon from "../../assets/images/user-icon.svg";
import { FC, useEffect, useState } from "react";
import {
  Container,
  ImgCointainer,
  ImgIcon,
  UserImg,
  SideContainer,
  SideLeftContainer,
  LogoImgContainer,
  BurgerContainer,
  NavLinkContainer,
  CustomLink,
  UserContainer,
  UserInfoContainer,
  Name,
  Email,
  LogoImg,
} from "./TopBarStyles";
import { useMe } from "../../hooks/useMe";
import { Link, useNavigate } from "react-router-dom";
import { GetNotificationList } from "../../api/NotificationApi";
import { Dropdown } from "react-bootstrap";
import LogoPhoto from "../../assets/images/logo_simple.png"
interface TopBarProps {
  setLeftBar: any;
  location: string;
}

export const TopBarComponent: FC<TopBarProps> = ({ setLeftBar, location }) => {
  const [user, setUser] = useState<any>({ permissions: [] });
  const [showDropdown, setShowDropdown] = useState(false);
  const toggleDropdown = () => setShowDropdown(!showDropdown);
  const navigate = useNavigate();
  const [notifications, setNotifications] = useState([]);
  const userInfo = useMe();
  const handleLeftBar = () => {
    setLeftBar((prev: boolean) => !prev);
  };
  useEffect(() => {
    if (userInfo.permissions) setUser(userInfo);
  }, [userInfo]);
  useEffect(() => {
    GetNotificationList().then((data) => {
      if (data)
        if (data.length > 0) {
          setNotifications(data);
        }
    });
  }, []);
  const checkPermission = (routeName: string) => user.permissions[routeName];
  return (
    <Container>
      <SideLeftContainer>
        <BurgerContainer>
          <ImgIcon onClick={handleLeftBar} src={burger} alt="burger" />
        </BurgerContainer>
        <LogoImgContainer>
          <img
            style={{height: "50px", width: "50px",marginRight: "1em"}}
            src={LogoPhoto}
            alt="logo"
          />
        </LogoImgContainer>
        <NavLinkContainer>
          <CustomLink selected={location === ""} to={"/"}>
            Dashboard
          </CustomLink>
          {checkPermission("clients") ? (
            <CustomLink selected={location === "client"} to={"/client"}>
              Clientes
            </CustomLink>
          ) : (
            <></>
          )}
          {checkPermission("employees") ? (
            <CustomLink selected={location === "employee"} to={"/employee"}>
              Empleados
            </CustomLink>
          ) : (
            <></>
          )}
          <CustomLink selected={location === "calendar"} to={"/calendar"}>
            Horario
          </CustomLink>
          {checkPermission("projects") ? (
            <CustomLink selected={location === "project"} to={"/project"}>
              Proyectos
            </CustomLink>
          ) : (
            <></>
          )}
          <CustomLink to={"/holiday"}>Vacaciones</CustomLink>
          {checkPermission("tasks") ? (
            <CustomLink selected={location === "task"} to={"/task"}>
              Tareas
            </CustomLink>
          ) : (
            <></>
          )}
          {checkPermission("turnos") ? (
            <CustomLink selected={location === "turnos"} to={"/turnos"}>
              Turnos
            </CustomLink>
          ) : (
            <></>
          )}
          {checkPermission("turnos") ? (
            <CustomLink selected={location === "fichajes"} to={"/fichajes"}>
              Fichajes
            </CustomLink>
          ) : (
            <></>
          )}
          {checkPermission("attachments") ? (
            <CustomLink selected={location === "attachment"} to={"/attachment"}>
              Adjuntos
            </CustomLink>
          ) : (
            <></>
          )}
        </NavLinkContainer>
      </SideLeftContainer>
      <SideContainer>
        <ImgCointainer
          style={{ display: "inline-block", position: "relative" }}
        >
          <Link to="/notifications">
            {notifications.length == 0 ? (
              <ImgIcon src={bell} alt="notifications bell" />
            ) : (
              <div>
                <ImgIcon src={bell} alt="notifications bell" />
                <span
                  style={{
                    position: "absolute",
                    top: 0,
                    marginLeft: "5px",
                    fontSize: "20px",
                    color: "white",
                  }}
                >
                  {notifications.length}
                </span>
              </div>
            )}
          </Link>
        </ImgCointainer>
        <ImgCointainer>
          <Link to="/chat">
            <ImgIcon src={messages} alt="notifications messages" />
          </Link>
        </ImgCointainer>
        <div style={{ display: "flex" }} onClick={toggleDropdown}>
          <UserContainer>
            <Link to={"/profile"}>
              <UserImg
                src={`${process.env.REACT_APP_BASE_URL}${user.photo!}`}
                alt="user profile"
              />
            </Link>
          </UserContainer>

          <UserInfoContainer>
            <Name>
              {userInfo.name} {userInfo.last_name}
            </Name>
            <Email>{userInfo.email}</Email>
          </UserInfoContainer>
        </div>
        {showDropdown && (
          <Dropdown
            style={{
              position: "absolute",
              right: "10em",
              top: "3em",
            }}
            show={showDropdown}
            onToggle={() => setShowDropdown(!showDropdown)}
          >
            <Dropdown.Menu align="start">
              <Dropdown.Item onClick={() => navigate("/profile")}>
                Ver perfil
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => {
                  localStorage.removeItem("token");
                  localStorage.removeItem("permissions");
                  navigate("/login");
                }}
              >
                Cerrar Sesión
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        )}
      </SideContainer>
    </Container>
  );
};
