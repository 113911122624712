import { useEffect } from "react"
import { SelectInputStyled } from "./selectStyled";
import { ProjectInterface } from "../../interfaces/project";
import { useFetchItems } from "../../hooks/useFetchItems";
import { ApiEndpoints } from "../../constants/urls";



export const ProjectFormSelectComponent = (props: {name:string, onChange: any,value?:number}) => {

    const {data,isLoading,error,fetchItems} = useFetchItems(ApiEndpoints.project)
    useEffect(() => {
        fetchItems()
    },[])

    return (
        <SelectInputStyled value={props.value} name={props.name} onChange={props.onChange}>
            {data && data.map((project: ProjectInterface,index: number) => 
                <option value={project.id}> {project.name}</option>
            )}
        </SelectInputStyled>
    )

}