import { Col, Container, Row } from "react-bootstrap";
import {
  EmployeeTableName,
  EmployeeTableText,
} from "../../../components/EmployeeList/employeeTableStyled";
import { useNavigate } from "react-router-dom";
import { TaskInterface } from "../../../interfaces/task";

export const TaskTableComponent = (props: {
  taskList: TaskInterface[];
  isEmployeePage: boolean;
  employeeName: string;
}) => {
  const navigate = useNavigate();
  return (
    <Container
      fluid
      style={{
        backgroundColor: "white",
        paddingTop: "1em",
        borderRadius: ".3em",
        height: "100%",
      }}
    >
      <Row
        style={{
          height: "2em",
          textAlign: "start",
          padding: " .5em 1em 1em 1em",
        }}
      >
        <Col lg={2}>
          <EmployeeTableName>Tarea</EmployeeTableName>
        </Col>
        <Col lg={2}>
          <EmployeeTableText>Proyecto</EmployeeTableText>
        </Col>
        <Col lg={2}>
          <EmployeeTableText>Estado</EmployeeTableText>
        </Col>
        {props.isEmployeePage ? (
          <Col lg={2}>
            <EmployeeTableText>Empleado</EmployeeTableText>
          </Col>
        ) : (
          <></>
        )}
        <Col lg={2}>
          <EmployeeTableText>Horas hoy</EmployeeTableText>
        </Col>
        <Col lg={2}>
          <EmployeeTableText>Horas total</EmployeeTableText>
        </Col>
      </Row>
      <hr
        style={{
          color: "rgba(149, 187, 232, 0.6)",
          borderWidth: "2px",
          marginTop: 0,
          marginBottom: 0,
        }}
      />
      {props.taskList.map((Task) => (
        <div
          onClick={() => {
            navigate(`/task/${Task.id!}`);
          }}
        >
          <Row
            style={{
              height: "3em",
              textAlign: "start",
              padding: "2em",
            }}
          >
            <Col lg={2}>
              <EmployeeTableName style={{ wordBreak: "break-word" }}>
                {Task.name}
              </EmployeeTableName>
            </Col>
            <Col lg={2}>
              <EmployeeTableText>{Task.project_name}</EmployeeTableText>
            </Col>
            <Col lg={2}>
              <EmployeeTableText>No trabajando</EmployeeTableText>
            </Col>
           {props.isEmployeePage ? <Col lg={2}>
              <EmployeeTableText>{Task.employee_name}</EmployeeTableText>
            </Col> : <></>}
            <Col lg={2}>
              <EmployeeTableText>{Task.today_time}</EmployeeTableText>
            </Col>
            <Col lg={2}>
              <EmployeeTableText>{Task.total_time}</EmployeeTableText>
            </Col>
          </Row>
          <hr
            style={{
              color: "rgba(149, 187, 232, 0.6)",
              borderWidth: "2px",
              marginTop: "1em",
              marginBottom: 0,
            }}
          />
        </div>
      ))}
    </Container>
  );
};
