import styled from "styled-components";

export const EmployeeTableText = styled.p`
  font-size: 1rem;
  line-height: 0.75rem;
  font-family: "Roboto";
  font-weight: 500;
  margin: 0;

  @media (max-width: 991px) {
    display: none;
  }
`;

export const EmployeeTableName = styled.p`
  font-size: 1rem;
  line-height: 0.75rem;
  font-family: "Roboto";
  font-weight: 500;
  margin: 0;
`;
