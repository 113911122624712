import { Col, Form, Row } from "react-bootstrap";
import { CardComponent } from "../../components/dashboard/cardComponent";
import { TitleText } from "../login/LoginStyles";
import { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import { AddButtonStyled } from "../../components/buttonComponent/buttonStyled";
import { useNavigate, useParams } from "react-router-dom";
import { LabelStyled } from "../../components/formComponents/labelStyled";
import { InputStyled } from "../../components/formComponents/inputStyled";
import { SelectInputStyled } from "../../components/formComponents/selectStyled";
import { ClientFormSelect } from "../../components/formComponents/clientFormSelect";
import { updateProjectApi } from "../../api/ProjectApiHandler";
import { EmployeeFormSelect } from "../../components/formComponents/employeeFormSelect";
import { useFetchItems } from "../../hooks/useFetchItems";
import { ApiEndpoints } from "../../constants/urls";

export const ProjectEditPage = () => {
	const navigate = useNavigate();
	const [name, setName] = useState<string>("");
	const [client, setClient] = useState<any>("");
	const [divisa, setDivisa] = useState<string>("");
	const [color, setColor] = useState<string>("");
	const [status, setStatus] = useState<string | undefined>("");
	const [projectBoss, setProjectBoss] = useState<any>(null);
	const [notifyWhenFinished, setNotifyWhenFinished] = useState(false)
	const [due_date, setDueDate] = useState("")
	const { id } = useParams();
	const { data, isLoading, error, fetchItems } = useFetchItems(
		ApiEndpoints.project,
		parseInt(id!)
	);

	useEffect(() => {
		fetchItems();
	}, []);

	useEffect(() => {
		if (data != null) {
			setName(data.name);
			setClient(data.client);
			setDivisa(data.divisa);
			setColor(data.color);
			setStatus(data.status);
			setNotifyWhenFinished(data.notify_when_finished)
			setDueDate(data.due_date.slice(0, 16))
			console.log(data.project_ow)
			setProjectBoss(data.project_ow)
		}
	}, [data]);

	const updateProjectHandler = async (event: any) => {
		event.preventDefault();
		const formData = event.target;
		const updateClient = await updateProjectApi(id!, {
			name: formData.name.value,
			client: formData.client.value,
			color: formData.color.value,
			divisa: formData.divisa.value,
			owner_user: formData.projectBoss.value,
			status: formData.status.value,
			notify_when_finished: formData.notify_when_finished.value,
			due_date: formData.due_date.value,
			project_ow: projectBoss
		});
		if (updateClient) navigate("/project",{ state: { refresh: true } });
	};
	return (
		<Container style={{ padding: "1em" }}>
			<TitleText>Actualizar proyecto</TitleText>
			<CardComponent>
				{data && (
					<form onSubmit={updateProjectHandler}>
						<Row>
							<Col
								lg={10}
								md={6}
								xs={12}>
								<Form.Group>
									<LabelStyled className="project_input_label">
										Nombre del proyecto
									</LabelStyled>
									<InputStyled
										name="name"
										className="project_input_field"
										type="text"
										placeholder="Nombre"
										value={name}
										onChange={(event) =>
											setName(event.target.value)
										}
									/>
								</Form.Group>
							</Col>
							<Col
								lg={2}
								md={6}
								xs={12}>
								<Form.Group>
									<LabelStyled>Divisa</LabelStyled>
									<SelectInputStyled
										name={"divisa"}
										value={divisa}
										onChange={(event) =>
											setDivisa(event.target.value)
										}>
										<option value="eur">EUR</option>
										<option value="usd">USD</option>
										<option value="ngn">NGN</option>
										<option value="cop">COP</option>
									</SelectInputStyled>
								</Form.Group>
							</Col>
						</Row>
						<Row style={{ marginTop: "2em" }}>
							<Col
								lg={10}
								md={6}
								xs={12}>
								<Form.Group>
									<LabelStyled className="project_input_label">
										Nombre del cliente
									</LabelStyled>
									<ClientFormSelect
										name={"client"}
										value={client.value}
										onChange={(event: any) =>
											setClient(event.target.value)
										}
									/>
								</Form.Group>
							</Col>
							<Col
								lg={2}
								md={6}
								xs={12}>
								<Form.Group>
									<LabelStyled>Color</LabelStyled>
									<SelectInputStyled
										name={"color"}
										value={color}
										onChange={(event) =>
											setColor(event.target.value)
										}>
										<option value="red">Rojo</option>
										<option value="blue">Azul</option>
										<option value="yellow">Amarillo</option>
									</SelectInputStyled>
								</Form.Group>
							</Col>
						</Row>
						<Row style={{ marginTop: "1em" }}>
							<Col lg={6}>
								<LabelStyled>Jefe de proyecto</LabelStyled>
								<EmployeeFormSelect
									name="projectBoss"
									onChange={(event: any) => {
										setProjectBoss(event.target.value);
									}}
									value={projectBoss}
								/>
							</Col>
							<Col lg={6}>
								<Row>
									<Col lg={6}>
										<LabelStyled>
											Estado del proyecto
										</LabelStyled>
										<SelectInputStyled
											name={"status"}
											value={status}
											onChange={(event) =>
												setStatus(event.target.value)
											}>
											<option value="iniciado">
												Iniciado
											</option>
											<option value="enCurso">
												En Curso
											</option>
											<option value="finalizado">
												Finalizado
											</option>
										</SelectInputStyled>
									</Col>
									<Col lg={6} className="justify-content-center">

										<Form.Check
											checked={notifyWhenFinished}
											type="switch"
											onChange={() => setNotifyWhenFinished(!notifyWhenFinished)}
											id="perm-timesheet"
											name="notify_when_finished"
											label="¿Notificar al finalizar?"
										/>
									</Col>
								</Row>
							</Col>
						</Row>
						<Row>
							<Col>
								<LabelStyled className="project_input_label">
									Fecha de entrega
								</LabelStyled>
								<Form.Control name="due_date" type="date" value={due_date} onChange={(event) => setDueDate(event.target.value)} data-testid="project-due-date-input" />
							</Col>
						</Row>
						<Row style={{ marginTop: "0.5em" }}>
							<Col lg={12}>
								<LabelStyled>
									* Configura la asignacion de empleados
									dentro del proyecto
								</LabelStyled>
							</Col>
						</Row>
						<Row
							style={{ marginTop: "2em" }}
							className="flex-row-reverse">
							<Col
								lg={2}
								md={2}
								xs={6}>
								<AddButtonStyled
									style={{
										borderRadius: "0.3em",
										padding: ".2em",
									}}>
									Guardar
								</AddButtonStyled>
							</Col>
						</Row>
					</form>
				)}
			</CardComponent>
		</Container>
	);
};
