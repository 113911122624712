import { Col, Form, Row } from "react-bootstrap";
import { CardComponent } from "../../components/dashboard/cardComponent";
import { TitleText } from "../login/LoginStyles";
import { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import { AddButtonStyled } from "../../components/buttonComponent/buttonStyled";
import { LabelStyled } from "../../components/formComponents/labelStyled";
import { InputStyled } from "../../components/formComponents/inputStyled";
import { SelectInputStyled } from "../../components/formComponents/selectStyled";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useCreateItem } from "../../hooks/useCreateItems";
import { ApiEndpoints } from "../../constants/urls";

export const EmployeeCreatePage = () => {
	const [endDateActive, setEndDateActive] = useState(false);
	const navigate = useNavigate();
	const { data, isLoading, error, createItem } = useCreateItem(
		`${ApiEndpoints.employee}/`
	);

	useEffect(() => {
		if (error !== null) {
			toast.error(error);
		} else if (data !== null) {
			navigate("/employee");
		}
	}, [error, data]);

	const createEmployeeFormHandler = async (event: any) => {
		event.preventDefault();
		let valid = true;
		const formData = event.target;
		const fields = [
			{ name: "name", message: "Es necesario rellenar el nombre del empleado" },
			{ name: "email", message: "Es necesario rellenar el email del empleado" },
			{ name: "bornDate", message: "Es necesario indicar la fecha de nacimiento del empleado" },
			{ name: "startDate", message: "Es necesario indicar la fecha de inicio de contrato del empleado" },
			{ name: "surnames", message: "Es necesario rellenar los apellidos del empleado" },
			{ name: "nif", message: "Es necesario rellenar el NIF del empleado" },
			{ name: "address", message: "Es necesario rellenar la dirección del empleado" },
		];

		// Verificación de campos vacíos
		for (const field of fields) {
			if (!formData[field.name].value) {
				toast.error(field.message);
				return; // Detener la ejecución si hay un campo vacío
			}
		}

		const item_response = await createItem({
			password: "",
			name: formData.name.value,
			last_name: formData.surnames.value,
			email: formData.email.value,
			contract_end_date: formData.endDate.value,
			contract_start_date: formData.startDate.value,
			born_date: formData.bornDate.value,
			is_staff: true,
			nif: formData.nif.value,
			address: formData.address.value,
			permissions: {
				attachments: formData.attachments.checked,
				clients: formData.clients.checked,
				employees: formData.employees.checked,
				turnos: formData.turnos.checked,
				projects: formData.projects.checked,
			},
		});
		if (!error) {
			navigate("/employee");
		}
	};
	
	return (
		<Container style={{ padding: "1em" }}>
			<TitleText>Nuevo empleado</TitleText>
			<CardComponent>
				<form onSubmit={createEmployeeFormHandler}>
					<Row>
						<Col
							lg={6}
							md={6}
							xs={12}>
							<Form.Group>
								<LabelStyled>Nombre</LabelStyled>
								<InputStyled
									name="name"
									className="project_input_field"
									type="text"
									placeholder="Nombre"
								/>
							</Form.Group>
						</Col>
						<Col
							lg={6}
							md={6}
							xs={12}>
							<Form.Group>
								<LabelStyled>Apellidos</LabelStyled>
								<InputStyled
									name="surnames"
									className="project_input_field"
									type="text"
									placeholder="Apellidos"
								/>
							</Form.Group>
						</Col>
					</Row>
					<Row style={{ marginTop: "1em" }}>
						<Col
							lg={6}
							md={6}
							xs={12}>
							<Form.Group>
								<LabelStyled>E-mail</LabelStyled>
								<InputStyled
									name="email"
									className="project_input_field"
									type="text"
									placeholder="email@email.com"
								/>
							</Form.Group>
						</Col>
						<Col
							lg={6}
							md={6}
							xs={12}>
							<Form.Group>
								<LabelStyled>Fecha de nacimiento</LabelStyled>
								<InputStyled
									name="bornDate"
									className="project_input_field"
									type="date"
									placeholder="dd/mm/yyyy"
								/>
							</Form.Group>
						</Col>
					</Row>

					<Row style={{ marginTop: "1em" }}>
						<Col
							lg={6}
							md={6}
							xs={12}>
							<Form.Group>
								<LabelStyled>
									Fecha de inicio de contrato
								</LabelStyled>
								<InputStyled
									name="startDate"
									className="project_input_field"
									type="date"
									placeholder="dd/mm/yyyy"
								/>
							</Form.Group>
						</Col>
						<Col
							lg={6}
							md={6}
							xs={12}>
							<Form.Group>
								<Form.Check
									type="switch"
									id="custom-switch"
									label="Fecha de fin"
									onChange={() => {
										setEndDateActive(!endDateActive);
									}}
								/>

								<InputStyled
									className="project_input_field"
									type="date"
									name="endDate"
									placeholder="dd/mm/aaa"
									readOnly={!endDateActive}
								/>
							</Form.Group>
						</Col>
					</Row>
					<Row style={{ marginTop: "1em" }}>
						<Col
							lg={6}
							md={12}
							xs={12}>
							<Form.Group>
								<LabelStyled>Cargo en la empresa</LabelStyled>
								<SelectInputStyled name="position">
									<option value="encargado">Encargado</option>
									<option value="empleado">Empleado</option>
									<option value="gestor">Gestor</option>
									<option value="gerente">Gerente</option>
								</SelectInputStyled>
							</Form.Group>
						</Col>
						<Col
							lg={6}
							md={12}
							xs={12}>
							<Form.Group>
								<LabelStyled>NIF</LabelStyled>
								<InputStyled
									className="project_input_field"
									type="text"
									name="nif"
									placeholder="NIF"
								/>
							</Form.Group>
						</Col>
					</Row>
					<Row style={{ marginTop: "1em" }}>
						<Col
							lg={12}
							md={12}
							xs={12}>
							<Form.Group>
								<LabelStyled>Dirección</LabelStyled>
								<InputStyled
									className="project_input_field"
									type="text"
									name="address"
									placeholder="Dirección"
								/>
							</Form.Group>
						</Col>
					</Row>
					<Row style={{ marginTop: "1em" }}>
						<Col>
							<Form.Group>
								<p
									style={{
										fontSize: "0.75rem",
										color: "rgba(48, 100, 217, 1)",
										marginBottom: "0.5em",
										fontWeight: 600,
									}}>
									Permisos que puede gestionar
								</p>
										<Form.Check
											type="switch"
											id="perm-employee"
											name="employees"
											label="Empleados"
										/>
										<Form.Check
											type="switch"
											id="perm-project"
											name="projects"
											label="Proyectos"
										/>
										<Form.Check
											type="switch"
											id="perm-timesheet"
											name="turnos"
											label="Turnos"
										/>
										<Form.Check
											type="switch"
											id="perm-client"
											name="clients"
											label="Clientes"
										/>
										<Form.Check
											type="switch"
											id="perm-files"
											name="attachments"
											label="Adjuntos"
										/>
									</Form.Group>
							
						</Col>
					</Row>
					<Row
						style={{ marginTop: "1em" }}
						className="flex-row-reverse">
						<AddButtonStyled
							style={{
								width: "25%",
								borderRadius: "0.3em",
								padding: ".2em",
							}}>
							Guardar
						</AddButtonStyled>
					</Row>
				</form>
			</CardComponent>
		</Container>
	);
};
