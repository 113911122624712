import FullCalendar from "@fullcalendar/react";
import { CalendarOptions, CalendarApi } from "@fullcalendar/core";

import timeGridPlugin from "@fullcalendar/timegrid";
import dayGridPlugin from "@fullcalendar/daygrid";

import { Button, Container, Modal } from "react-bootstrap";
import esLocale from "@fullcalendar/core/locales/es";
import "./calendarWeek.css";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

export const CalendarWeekComponent = (props: {
  events: any;
  onlyDay: boolean;
}) => {
  const calendarRef = useRef<FullCalendar>(null);
  const [currentView, setCurrentView] = useState("dayGridMonth");
  const [eventSelected, setEventSelected] = useState<null | any>(null);
  const navigate = useNavigate();
  useEffect(() => {
    const updateViewForScreenSize = () => {
      const calendarApi: CalendarApi | undefined =
        calendarRef.current?.getApi();
      if (window.innerWidth < 768) {
        // Considera menos de 768px como dispositivo móvil
        setCurrentView("dayGridDay");
        if (calendarApi) {
          calendarApi.changeView("dayGridDay");
        }
      } else {
        setCurrentView("dayGridMonth");
        if (calendarApi) {
          calendarApi.changeView("dayGridMonth");
        }
      }
    };

    // Escucha los cambios en el tamaño de la ventana
    window.addEventListener("resize", updateViewForScreenSize);
    // Ejecuta una vez para establecer la vista inicial correcta
    updateViewForScreenSize();

    // Limpia el evento al desmontar el componente
    return () => {
      window.removeEventListener("resize", updateViewForScreenSize);
    };
  }, []);
  const headerToolbar = {
    start: "", // Puedes dejar esto vacío o ajustar según sea necesario
    center: "title",
    end: props.onlyDay
      ? "prev,next,dayGridDay"
      : "prev,next dayGridMonth,dayGridDay",
  };

  return (
    <>
      <Container
        fluid
        style={{
          backgroundColor: "white",
          paddingTop: "1em",
          borderRadius: ".3em",
          height: "100%",
          width: "100%",
          margin: "auto",
        }}
      >
        <FullCalendar
          contentHeight={"auto"}
          plugins={
            props.onlyDay ? [dayGridPlugin] : [timeGridPlugin, dayGridPlugin]
          }
          initialView={ "dayGridDay" }
          weekends={true}
          locale={esLocale}
          slotDuration={"00:30:00"}
          slotLabelInterval={"00:30:00"}
          slotMinTime={"07:00:00"}
          slotMaxTime={"22:00:00"}
          dayHeaderFormat={{ weekday: "short", day: "numeric" }}
          dayHeaderClassNames={"headerCalendar"}
          allDaySlot={false}
          aspectRatio={0}
          ref={calendarRef}
          headerToolbar={headerToolbar}
          events={props.events}
          eventClick={(clickInfo: any) => {
            setEventSelected(clickInfo.event);
          }}
          slotEventOverlap={false}
          views={{
            dayGridMonth: {
              // Personaliza el dayHeaderFormat solo para la vista de mes
              dayHeaderFormat: { weekday: "short", omitCommas: true },
            },
          }}
        />
      </Container>
      {eventSelected && (
        <Modal centered show={eventSelected !== null}>
          <Modal.Header>
            <Modal.Title>Información del evento</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {eventSelected!.title ? (
              <p>Empleado y Tarea: {eventSelected!.title} </p>
            ) : (
              <></>
            )}
            {eventSelected!.start ? (
              <p>Hora de inicio: {eventSelected!.start.toLocaleString()} </p>
            ) : (
              <></>
            )}
            {eventSelected!.end ? (
              <>
                <p>Hora de fin: {eventSelected!.end.toLocaleString()} </p>
              </>
            ) : (
              <>
                {" "}
                <p>Hora de fin: <span className="text-muted">La tarea aún no tiene hora de fin.</span></p>
              </>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="primary"
              onClick={() => {
                navigate(`/inputacion/${eventSelected.id}`);
              }}
            >
              Editar imputación
            </Button>
            <Button
              variant="secondary"
              onClick={() => {
                setEventSelected(null);
              }}
            >
              Cerrar
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </>
  );
};
