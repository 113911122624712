import { Col, Form, Row } from "react-bootstrap";
import { CardComponent } from "../../components/dashboard/cardComponent";
import { TitleText } from "../login/LoginStyles";
import Container from "react-bootstrap/Container";
import { AddButtonStyled } from "../../components/buttonComponent/buttonStyled";
import { useNavigate, useParams } from "react-router-dom";
import { LabelStyled } from "../../components/formComponents/labelStyled";
import { InputStyled } from "../../components/formComponents/inputStyled";
import { toast } from "react-toastify";
import { useEffect, useState } from "react";

import {
  deleteTimeSheetRecordApi,
  getTimeSheetRecordApi,
  updateTimesheetRecordDetailApi,
} from "../../api/TurnosApi";
import { TaskSOptionCmponent } from "../../components/formComponents/taskSelectComponent";
import { SelectButtonStyled } from "../../components/EmployeeComponents/buttonTypes";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";

export const InputacionEditPage = () => {
  const MySwal = withReactContent(Swal);

  const [inputacion, setInputacion] = useState<any>(null);
  const [taskSelected, setTaskSelected] = useState("");
  const [startTime, setStartTime] = useState<any>(new Date());
  const [endTime, setEndTime] = useState<any>(new Date());
  const { id } = useParams();
  const navigate = useNavigate()
  useEffect(() => {
    const fetch = async () => {
      const data = await getTimeSheetRecordApi(id!);
      setInputacion(data);
      if (data != null) {
        setTaskSelected(data.task);
        setStartTime(data.start_time.slice(0, 16));

        setEndTime(data.end_time.slice(0, 16));
      }
    };
    fetch();
  }, []);
  const updateInputacionHandler = async (event: any) => {
    event.preventDefault();
    console.log(taskSelected)
    if (taskSelected === "Selecciona la tarea") {
      toast.error("Es necesario seleccionar una tarea")
      return
    }
    const update = await updateTimesheetRecordDetailApi(id!, {
      start_time: startTime,
      end_time: endTime,
      task: taskSelected,
    });
    if (update)
      toast.success("Imputación actualizada correctamente")
    else
      toast.error("Error al actualizar la inputacion")
  };
  return (
    <Container style={{ padding: "1em" }}>
      <Container style={{ display: "flex", justifyContent: "space-between" }}>
        <TitleText>Editar Imputación</TitleText>
        <SelectButtonStyled
          active={false}
          onClick={() => {
            MySwal.fire({
              title: "¿Estas seguro de eliminar la imputación? ",
              text: "Al eliminar la imputación afectará a la tareas y proyectos relacionados",
              showCancelButton: true,
              confirmButtonText: "Eliminar",
              cancelButtonText: `No eliminar`,
              confirmButtonColor: "red",
            }).then(async (result) => {
              if (result.isConfirmed) {
                await deleteTimeSheetRecordApi(id!)
                toast.success("Imputación eliminada correctamente")
                setTimeout(() => {
                  navigate("/calendar")
                }, 1000)
              }
            })
          }}>
          Eliminar
        </SelectButtonStyled>
      </Container>
      <CardComponent>
        {inputacion && (
          <form onSubmit={updateInputacionHandler}>
            <Row>
              <Col lg={6} md={12} xs={12}>
                <LabelStyled>Tarea</LabelStyled>

                <TaskSOptionCmponent
                  name={"task"}
                  onChange={(event: any) => {
                    setTaskSelected(event.target.value);
                  }}
                  value={taskSelected}
                />
              </Col>
            </Row>
            <Row>
              <Col lg={6} md={12} xs={12}>
                <Form.Group>
                  <LabelStyled>Hora de inicio </LabelStyled>
                  <InputStyled
                    name="name"
                    className="project_input_field"
                    type="datetime-local"
                    onChange={(e) => setStartTime(e.target.value)}
                    value={
                      startTime
                    }
                  />
                </Form.Group>
              </Col>
              <Col lg={6} md={12} xs={12}>
                <Form.Group>
                  <LabelStyled>Hora de Fin </LabelStyled>
                  <InputStyled
                    name="name"
                    className="project_input_field"
                    type="datetime-local"
                    onChange={(e) => setEndTime(e.target.value)}
                    value={endTime}
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row style={{ marginTop: "2em" }} className="flex-row-reverse">
              <Col lg={2} md={2} xs={6}>
                <AddButtonStyled
                  style={{
                    borderRadius: "0.3em",
                    padding: ".2em",
                  }}
                >
                  Guardar
                </AddButtonStyled>
              </Col>
            </Row>
          </form>
        )}
      </CardComponent>
    </Container>
  );
};
