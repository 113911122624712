import axios from "axios";
import { apiClient } from "./axiosClient";

export const createTurnoApi = async (turnoInfo: any) => {
	try {
		const token = localStorage.getItem("token");
		console.log(turnoInfo);
		const login = await apiClient.post(
			`${process.env.REACT_APP_BASE_URL}api/v1/turnos/`,
			turnoInfo,
			{
				headers: {
					Authorization: `Bearer ${token}`,
				},
			}
		);
		if (login.status == 200) return login.data;
		return false;
	} catch (error) {
		return false;
	}
};
export const createTurnoPlanApi = async (turnoInfo: any) => {
	try {
		const token = localStorage.getItem("token");
		const login = await apiClient.post(
			`${process.env.REACT_APP_BASE_URL}api/v1/plan/turnos`,
			turnoInfo,
			{
				headers: {
					Authorization: `Bearer ${token}`,
				},
			}
		);
		if (login.status == 200) return login.data;
		return false;
	} catch (error) {
		return false;
	}
};

export const getTurnosApi = async (
	time: string,
	next?: string,
	query_start?: string
) => {
	try {
		const token = localStorage.getItem("token");
		const login = await apiClient.get(
			`${process.env.REACT_APP_BASE_URL}api/v1/time-sheets/turnos?time=${time}&query_start=${query_start}&next=${next}`,
			{
				headers: {
					Authorization: `Bearer ${token}`,
				},
			}
		);
		if (login.status == 200) {
			console.log(login.data);
			return login.data;
		}
		return false;
	} catch (error) {
		return false;
	}
};

export const getFichajes = async (type: string,time: string) => {
	try {

		const token = localStorage.getItem("token");
		const fichajesList = await apiClient.get(
			`${process.env.REACT_APP_BASE_URL}api/v1/fichajes?query=${type}&time=${time}`,
			{
				headers: {
					Authorization: `Bearer ${token}`,
				},
			}
		);
		if (fichajesList.status == 200) {
			return fichajesList.data;
		}
		return false;
	} catch (error) {
		return false;
	}
};

export const updateTurnoPlanApi = async (turnoList: any, employeeList: any) => {
	try {
		const token = localStorage.getItem("token");
		const login = await apiClient.put(
			`${process.env.REACT_APP_BASE_URL}api/v1/plan/turnos?turnos=${turnoList}`,
			{
				employee: employeeList,
			},
			{
				headers: {
					Authorization: `Bearer ${token}`,
				},
			}
		);
		if (login.status == 200) return login.data;
		return false;
	} catch (error) {
		console.log(error);
		return false;
	}
};

export const getTimeSheetsApi = async () => {
	const token = localStorage.getItem("token");

	const fichajesList = await apiClient.get(
		`${process.env.REACT_APP_BASE_URL}api/v1/time-sheets`,
		{
			headers: {
				Authorization: `Bearer ${token}`,
			},
		}
	);
	console.log(fichajesList);
	if (fichajesList.status == 200) {
		return fichajesList.data;
	}
	return false;
};

export const deleteTurnoApi = async (id: any) => {
	const token = localStorage.getItem("token");
	console.log("DELETE")
	console.log(`${process.env.REACT_APP_BASE_URL}api/v1/time-sheets/turnos/${id}`)
	const fichajesList = await apiClient.delete(
		
		`${process.env.REACT_APP_BASE_URL}api/v1/time-sheets/turnos/${id}`,
		{
			headers: {
				Authorization: `Bearer ${token}`,
			},
		}
	);
	if (fichajesList.status == 204) {
		return true;
	}
	return false;
};



export const getTimeSheetRecordApi = async (id: number | string) => {
	const token = localStorage.getItem("token");

	const fichajesList = await apiClient.get(
		`${process.env.REACT_APP_BASE_URL}api/v1/time-sheets/records/${id}`,
		{
			headers: {
				Authorization: `Bearer ${token}`,
			},
		}
	);
	console.log(fichajesList);
	if (fichajesList.status == 200) {
		return fichajesList.data;
	}
	return false;
};

export const updateTimesheetRecordDetailApi = async (id: number | string ,content: any) => {
	try {
		const token = localStorage.getItem("token");
		const request = await axios.put(
			`${process.env.REACT_APP_BASE_URL}api/v1/time-sheets/records/${id}/`,content,
			{
				headers: {
					Authorization: `Bearer ${token}`,
				},
			}
		);
		return true;
	} catch (error) {
		return false;
	}
};

export const createTimesheetRecordDetailApi = async (content:any) => {
	try {
		const token = localStorage.getItem("token");
		const r = await axios.post(`${process.env.REACT_APP_BASE_URL}api/v1/records`,
			content,
			{
				headers:{
					"Authorization": `Bearer ${token}`
				}
			})
		
		return r.data;
	} catch (error) {
		console.log(error)
		return false;
	}
};


export const deleteTimeSheetRecordApi = async (id: number | string) => {
	const token = localStorage.getItem("token");

	const fichajesList = await apiClient.delete(
		`${process.env.REACT_APP_BASE_URL}api/v1/time-sheets/records/${id}`,
		{
			headers: {
				Authorization: `Bearer ${token}`,
			},
		}
	);
	console.log(fichajesList);
	if (fichajesList.status == 200) {
		return fichajesList.data;
	}
	return false;
};

